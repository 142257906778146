import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ChevronRight } from "lucide-react";
import {
  getHotelReservationById,
  unlockDoor,
  getPasscode,
  checkIn,
} from "../services/api";
import UnlockingPreloader from "../components/UnlockingPreloader";
import BottomNavigation from "../components/BottomNavigation";
import "../styles/UnlockingDetails.css";
import hotelImg from "../assets/hotel-checkIn-img.jpg";

const UnlockingDetails = () => {
  const [reservationData, setReservationData] = useState(null);
  const [canUnlock, setCanUnlock] = useState(false);
  const [redirectToHelpSupport, setRedirectToHelpSupport] = useState(false);
  const [buttonText, setButtonText] = useState("Tap to Unlock Front Door");
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [checkInMessage, setCheckInMessage] = useState("");
  const [isDoorKeypad, setIsDoorKeypad] = useState(true);
  const [isUnlockingPreloader, setIsUnlockingPreloader] = useState(false);
  // eslint-disable-next-line
  const [passcodeProvided, setPasscodeProvided] = useState(false);
  // eslint-disable-next-line
  const [canUnlockRooms, setCanUnlockRooms] = useState(false);
  // eslint-disable-next-line
  const [frontDoorUnlocked, setFrontDoorUnlocked] = useState(false);
  const { reservationId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.canUnlockRooms) {
      setCanUnlockRooms(true);
    }
  }, [location]);

  useEffect(() => {
    const fetchReservationData = async () => {
      try {
        const response = await getHotelReservationById(reservationId);
        if (response.success) {
          setReservationData(response.data[0]);
          checkUnlockingTime(response.data[0]);
          setIsDoorKeypad(response.data[0].hotelDetails.isDoorKeypad);
        }
      } catch (error) {
        console.error("Error fetching reservation data:", error);
      }
    };
    fetchReservationData();
    setCanUnlockRooms(false);
    // eslint-disable-next-line
  }, [reservationId]);

  const checkUnlockingTime = (data) => {
    const now = new Date();
    const checkInTime = new Date(
      data.bookingDetails.checkInDate +
        "T" +
        data.hotelDetails.defaultCheckInTime
    );
    const halfHourBeforeCheckIn = new Date(checkInTime.getTime() - 30 * 60000);

    const canUnlock = now >= halfHourBeforeCheckIn;
    console.log("Can unlock:", canUnlock);
    setCanUnlock(canUnlock);

    updateUnlockMessage(now, halfHourBeforeCheckIn, checkInTime);
  };

  const updateUnlockMessage = (now, unlockTime, checkInTime) => {
    if (now < unlockTime) {
      const timeDiff = unlockTime.getTime() - now.getTime();
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

      if (daysDiff > 1) {
        setButtonText(`Unlock will be available in ${daysDiff} days`);
      } else if (daysDiff === 1) {
        setButtonText(`Unlock will be available tomorrow`);
      } else {
        const hoursDiff = Math.floor(timeDiff / (1000 * 3600));
        const minutesDiff = Math.ceil((timeDiff % (1000 * 3600)) / (1000 * 60));

        if (hoursDiff > 0) {
          setButtonText(`Unlock available in ${hoursDiff}h ${minutesDiff}m`);
        } else {
          setButtonText(`Unlock available in ${minutesDiff} minutes`);
        }
      }
    } else {
      setButtonText("Tap to Unlock Front Door");
    }
  };

  const handleUnlock = async () => {
    if (redirectToHelpSupport) {
      navigate("/help-support");
      return;
    }

    console.log("Current canUnlock state:", canUnlock);
    console.log("Current isUnlocked state:", isUnlocked);
    if (canUnlock && !isUnlocked) {
      setIsUnlockingPreloader(true);
      try {
        const token = reservationData.guestDetails.ttLockAccessToken;
        const room = "2024";
        const propertyId = reservationData.hotelDetails.propertyId;

        const unlockPromise = unlockDoor(token, room, propertyId);
        const timeoutPromise = new Promise((_, reject) =>
          setTimeout(
            () => reject(new Error("Unlock operation timed out")),
            20000
          )
        );

        const response = await Promise.race([unlockPromise, timeoutPromise]);

        if (response.success) {
          setButtonText("Front Door Has Been Unlocked Successfully!");
          setIsUnlocked(true);
          setFrontDoorUnlocked(true);
          setCanUnlockRooms(true);
        } else {
          throw new Error("Unlock failed");
        }
      } catch (error) {
        console.error("Error unlocking door:", error);

        if (!isDoorKeypad) {
          setButtonText("Unlocking Failed, Connect To Support Team.");
          setRedirectToHelpSupport(true);
        } else {
          setButtonText("Failed to unlock. Fetching passcode...");

          try {
            const passcodeResponse = await getPasscode(reservationId, "2024");
            if (passcodeResponse && passcodeResponse.passcode) {
              const passcodeMessage = `Unlocking Failed. Try Passcode: ${passcodeResponse.passcode}#`;
              setButtonText(passcodeMessage);
              setPasscodeProvided(true);
              setCanUnlockRooms(true);
            } else {
              throw new Error("Failed to get passcode");
            }
          } catch (passcodeError) {
            console.error("Error fetching passcode:", passcodeError);
            setButtonText("Failed to get passcode. Please try again.");
          }
        }
      } finally {
        setIsUnlockingPreloader(false);
      }
    } else if (!canUnlock) {
      console.log("Cannot unlock yet");
    } else {
      console.log("Door is already unlocked");
    }
  };

  const handleRoomClick = async (room) => {
    if (!reservationData) return;

    const { bookingDetails, hotelDetails } = reservationData;

    // Check if the status is "In-House"
    if (bookingDetails.status === "In-House") {
      const roomIdentifier = room.roomName || room.roomId;
      navigate(`/unlock-room/${reservationId}/${roomIdentifier}`);
      return;
    }

    const payload = {
      bookingid: bookingDetails._id,
      reservationid: bookingDetails.reservationId,
      propertyID: hotelDetails.propertyId,
    };

    setIsUnlockingPreloader(true);

    try {
      const token = localStorage.getItem("token");
      const response = await checkIn(payload, token);

      if (
        response.success ||
        response.message === "Checkin Succesfull and mail sent sucess"
      ) {
        // navigate(`/unlock-room/${reservationId}/${roomName}`);
        const roomIdentifier = room.roomName || room.roomId;
        navigate(`/unlock-room/${reservationId}/${roomIdentifier}`);
      } else {
        if (
          response.message === "Your Payment is due, Kindly pay at Reception."
        ) {
          setCheckInMessage(response.message);
        } else if (
          response.message ===
          "Room check-in issue. Ensure previous checkouts are complete."
        ) {
          setCheckInMessage(response.message);
          // navigate(`/unlock-room/${reservationId}/${roomName}`);
          const roomIdentifier = room.roomName || room.roomId;
          navigate(`/unlock-room/${reservationId}/${roomIdentifier}`);
        } else {
          setCheckInMessage(
            response.message ||
              "An error occurred during check-in. Please try again."
          );
        }
      }
    } catch (error) {
      console.error("Check-in error:", error);
      setCheckInMessage("An error occurred during check-in. Please try again.");
    } finally {
      setIsUnlockingPreloader(false);
    }
  };

  if (!reservationData || isUnlockingPreloader) {
    return <UnlockingPreloader />;
  }

  const { hotelDetails, bookingDetails } = reservationData;

  return (
    <div className="unlocking-details-wrapper">
      <div className="container">
        <p className="UnlockingDetailHeading">Unlocking Details</p>

        <div className="unlocking-hotel-card">
          <img
            src={hotelDetails.propertyImage || hotelImg}
            alt={hotelDetails.propertyName}
            className="hotel-image"
          />
          <div className="hotel-overlay"></div>
          <div className="hotel-name">{hotelDetails.propertyName}</div>
        </div>

        <div className="frontdoor-btn">
          {hotelDetails.isFrontDoor && (
            <div className="unlockbtn">
              <h3>Front Door Key:</h3>
              <button
                className={`unlock-button-frontdoor ${
                  !canUnlock || isUnlocked ? "disabled" : ""
                }`}
                onClick={handleUnlock}
                disabled={!canUnlock || isUnlocked}
              >
                {buttonText}
              </button>
            </div>
          )}
        </div>

        {checkInMessage && <p className="check-in-message">{checkInMessage}</p>}

        <div className="room-list">
          <h3>Room List:</h3>
          {bookingDetails.rooms.map((room, index) => (
            <button
              // key={room.roomName}
              key={index}
              className="room-item"
              onClick={() => handleRoomClick(room)}
            >
              <span className="room-item-text">
                Room Number:{" "}
                {room.roomName || room.roomId || `Room ${index + 1}`}
                {/* {room.roomName} */}
              </span>
              <span className="arrow">
                <ChevronRight />
              </span>
            </button>
          ))}
        </div>
      </div>
      <BottomNavigation />
    </div>
  );
};

export default UnlockingDetails;
