import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowLeft, Mail, Phone, Copy } from "lucide-react";
import "../styles/Help&Support.css";
import BottomNavigation from "../components/BottomNavigation";

const HelpSupport = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const reservationData = location.state?.reservationData;
  const [notification, setNotification] = useState(null);

  const propertyEmail =
    reservationData?.hotelDetails?.propertyEmail ||
    reservationData?.hotelDetails?.emailId ||
    "Not available";
  const propertyPhone =
    reservationData?.hotelDetails?.propertyPhone ||
    reservationData?.hotelDetails?.phoneNo ||
    "Not available";

  const handleEmailClick = () => {
    window.location.href = `mailto:${propertyEmail}`;
  };

  const handlePhoneClick = () => {
    window.location.href = `tel:${propertyPhone}`;
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setNotification("Copied to clipboard!");
      setTimeout(() => setNotification(null), 3000);
    });
  };

  return (
    <div className="helpContainer">
      <div className="header">
        <button className="back-button" onClick={() => navigate(-1)}>
          <ArrowLeft />
        </button>
        <h1>Help & Support</h1>
      </div>

      {/* Remove the static support row */}
      <div className="support-item" onClick={handleEmailClick}>
        <Mail size={20} />
        <span>{propertyEmail}</span>
        <Copy
          size={20}
          onClick={(e) => {
            e.stopPropagation();
            handleCopy(propertyEmail);
          }}
        />
      </div>
      <div className="support-item" onClick={handlePhoneClick}>
        <Phone size={20} />
        <span>{propertyPhone}</span>
        <Copy
          size={20}
          onClick={(e) => {
            e.stopPropagation();
            handleCopy(propertyPhone);
          }}
        />
      </div>
      {notification && <div className="notification">{notification}</div>}
      <BottomNavigation />
    </div>
  );
};

export default HelpSupport;
