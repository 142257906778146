import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import "../styles/UnlockRoom.css";
import lockIcon from "../assets/unlockSucess.png";
import BottomNavigation from "../components/BottomNavigation";

const UnlockSuccess = () => {
  const navigate = useNavigate();
  const { roomIdentifier } = useParams();

  return (
    <div className="unlock-room">
      <div className="header">
        <button className="back-button" onClick={() => navigate(-1)}>
          <ArrowLeft />
        </button>
        <h1>Unlock Successful</h1>
      </div>
      <div className="room-number">
        <span>Room: {roomIdentifier}</span>
      </div>
      <div className="unlock-room-container">
        <div className="lock-icon">
          <img src={lockIcon} alt="Unlock Success" />
        </div>
        <button className="unlock-button unlocked" disabled>
          Room Unlocked Successfully
        </button>
      </div>
      <BottomNavigation />
    </div>
  );
};

export default UnlockSuccess;
